.inputBox {
  position: relative;

  input {
    font-size: 15px;
    color: #222222;
    width: 100%;
    border: none;
    border-bottom: solid #aaaaaa 1px;
    padding-bottom: 10px;
    padding-left: 10px;
    position: relative;
  }

  input::placeholder {
    color: #aaaaaa;
  }

  input:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    bottom: 0;
    background-color: #666;
    left: 0;
    width: 0;
    height: 2px;
    border-radius: 2px;
    transition: 0.5s;
  }

  label {
    position: absolute;
    color: #aaa;
    left: 10px;
    font-size: 20px;
    bottom: 8px;
    transition: all 0.2s;
    cursor: pointer;
  }

  input:focus ~ label,
  input:valid ~ label {
    font-size: 12px;
    bottom: 40px;
    color: #666;
    font-weight: bold;
  }

  input:focus ~ span,
  input:valid ~ span {
    width: calc(100% + 15px);
  }
}
