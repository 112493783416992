.chat-list-div {
    background-color: #f0f0f0;
    border-right: 1px solid #ccc;
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-width: 170px;

    .new_chat_token_btn {
        background-image: linear-gradient(
            to right,
            #222222 0%,
            #434343 51%,
            #000000 100%
        );
        margin: 10px;
        width: calc(100% - 20px);
        font-size: 14px;
        padding: 15px 0px 15px 0px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        border-radius: 10px;
        display: block;
        cursor: pointer;

        &:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
        }
    }

    .chat-list-title {
        height: 80%;
        overflow-y: scroll;

        ul {
            list-style-type: none;
            padding: 0;

            li {
                max-width: 100%;
                padding: 10px 15px; // Added padding for better spacing
                margin: 5px 0; // Added margin for spacing between list items
                border-radius: 4px;
                cursor: pointer;
                background-color: transparent;
                transition: background-color 0.3s ease; // Smooth background transition
                text-overflow: ellipsis;
                white-space: nowrap; // Ensure the text stays on one line
                overflow: hidden;
                position: relative;

                &:hover {
                    background-color: #e6e6e6; // Hover effect
                }

                &.selected {
                    background-color: #c8e6c9; // Selected item background
                }
            }
        }
    }
}
