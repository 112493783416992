.agent-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .table-button-container {
        width: 1200px;
    }
    .agent-create-button-container {
        width: 100%;

        .agent-create-button {
            font-size: 14px;
            color: white;
            background-color: #007bff;
            border: none;
            padding: 10px 20px;
            border-radius: 12px;
            cursor: pointer;
            margin-top: 20px;
        }
    }
}
