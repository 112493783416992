body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", "remixicon", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

.root {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    overflow: hidden;
}

.middle {
    width: 100%;
    height: calc(100% - 100px);
    position: absolute;
    display: flex;
    overflow: hidden;
}

/*constant*/
@import "styles/ref/remixicon.css";

/* molecules */
@import "../src/components/pages/index.scss";

/* atoms */
@import "../src/components/atoms/index.scss";
