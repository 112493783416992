@import "./ChatSetting/_style.scss";

.chat-input {
    z-index: 3;
    display: flex;
    width: calc(100% - 20px);
    bottom: 0;
    padding: 10px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 8px;

    input {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
        font-size: 1rem; // Improved readability
    }

    .sendBtn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem; // Larger button text for accessibility
        transition: background-color 0.3s ease; // Smooth background transition

        &:hover {
            background-color: #0056b3;
        }
    }

    .settingsModal {
        position: absolute;
        right: 110px;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 20px;
        color: #434343;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
}
