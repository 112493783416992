.ai-message {
    display: flex;
    width: calc(100% - 40px);
    max-width: 100%;
    padding: 10px;
    border-radius: 15px;
    flex-wrap: wrap;

    img {
        width: 20px;
        padding: 8px;
        border-radius: 100%;
        background-color: #eeeeee;
        height: 20px;
        margin-right: 15px;
    }

    .ai-text {
        max-width: 80%;
        div {
            right: 0;
            width: auto;
            padding: 10px;
            border-radius: 8px;
            background-color: #e1f5fe;
            word-wrap: break-word;
            position: relative;

            .loading-text {
                font-size: 1rem;
                text-align: center; /* 중앙 정렬 */
                animation: fadeIn 1s ease-in-out infinite; /* 애니메이션 */
            }
        }
    }

    .ai-text-container {
        display: flex;
        align-items: center;
    }

    .loading-overlay {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .ai-time {
        width: 10%;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        margin-left: 10px;
    }

    .break {
        flex-basis: 100%;
    }

    .ai-info {
        display: flex;
        margin-top: 5px;
        margin-left: 40px;

        div {
            margin-left: 10px;
            border-radius: 20px;
            padding: 5px 10px 5px 10px;
            color: white;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            background-color: #007bff;
            margin-bottom: 3px;
        }

        div:nth-child(2) {
            background-color: #ffa657;
            margin-bottom: 3px;
        }

        div:nth-child(3) {
            background-color: #1a7f37;
            margin-bottom: 3px;
        }

        div:nth-child(4) {
            background-color: #a371f7;
            margin-bottom: 3px;
        }

        div:nth-child(3) {
            background-color: #1a7f37;
            margin-bottom: 3px;
        }
    }
}

.user-message {
    width: calc(100% - 20px);
    display: flex;
    padding: 10px;
    border-radius: 15px;
    text-align: right;
    word-wrap: break-word;
    left: 0;

    .user-time {
        //min-width: 20%;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        flex: 1;
        margin-right: 10px;
    }

    .user-text div {
        text-align: left;
        display: inline-block;
        max-width: 800px;
        padding: 10px;
        border-radius: 8px;
        background-color: #c8e6c9;
        position: relative;
        word-wrap: break-word; /* Internet Explorer 5.5+ */
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-break: break-all;
    }
}
