.user_file_size {
    //background-color: #0a3069;
}
.Chart {
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        min-height: 500px;
    }
}
