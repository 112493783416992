.fileUploadModal {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

    .fileDragAndDropBox {
        width: 100%;
        margin-top: 20px;
        text-align: center;
        border: 2px #007bff dashed;
        border-radius: 15px;
        height: 150px;
        font-size: 30px;
        color: #007bff;

        .btn-upload {
            padding: 20px 0px 0px 0px;
            height: 100%;
            border-radius: 10px;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            i {
                font-size: 50px;
                font-weight: 500;
            }
        }

        #file {
            display: none;
        }
    }

    .fileList {
        margin-top: 20px;
        width: 100%;
        height: 50%;
        overflow-y: scroll;
        overflow-x: hidden;

        .uploadFileInfo {
            display: flex;
            text-align: left;
            margin-top: 5px;
            height: 80px;
            border-radius: 12px;
            background-color: #eeeeee;
            line-height: 50px;

            i {
                line-height: 80px;
                margin-left: 15px;
                font-size: 30px;
            }

            .removeFile {
                flex: 1;
                text-align: right;
                line-height: 80px;
                font-size: 30px;
                margin-right: 15px;
                i {
                    opacity: 0.5;
                }
                i:hover {
                    opacity: 1;
                    cursor: pointer;
                    color: black;
                }
            }

            .fileTitle {
                line-height: 80px;
                margin-left: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 50%;
            }

            .fileSize {
                text-align: right;
                line-height: 80px;
                margin-left: 20px;
                width: 15%;
            }
        }
    }
}
