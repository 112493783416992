.Header {
    position: relative;
    height: 100px;
    background-color: #f2f2f2f2;
    //text-align: center;
    border-bottom: 0.5px solid gray;

    .logo_img {
        padding: 25px;
        max-height: 50%;
        width: auto;
    }

    .login_info {
        position: absolute;
        display: flex;
        top: 25px;
        right: 70px;

        .user_img {
            font-size: 34px;
            margin-right: 10px;
        }

        .user_info {
            display: contents;
            line-height: 50px;

            .user_name {
                font-size: 18px;
                margin-right: 5px;
            }

            .notice {
                color: gray;
                font-size: 12px;
            }
        }
    }

    .logout_btn {
        position: absolute;
        right: 10px;
        top: 25px;
        margin-left: 20px;
        font-size: 34px;
        opacity: 0.4;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    .settings_toggle {
        margin-top: 10px;
        padding: 8px 12px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }

    .settings_list {
        position: absolute;
        top: 100%; /* 부모 요소 아래로 배치 */
        left: 0;
        z-index: 1001; /* 메뉴를 위로 올림 */
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        .settings_item {
            padding: 10px 15px;
            font-size: 14px;
            color: #333;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f8f9fa;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }
        }
    }
}

@media (max-width: 500px) {
    .Header {
        .login_info {
            display: revert;
            text-align: center;
            right: 50px;
            margin-top: -10px;

            .user_info {
                .user_name {
                    margin-top: -20px;
                }
                .notice {
                    display: none;
                }
            }
        }
    }
}
