.messages {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    @media (max-width: 500px) {
        .ai-message {
            width: 90%;
            img {
                display: none;
            }

            .ai-text {
                width: auto;
            }

            .ai-time {
                margin-left: 2px;
            }

            .break {
                flex-basis: 0;
            }

            .ai-info {
                margin-left: 0;
            }
        }
    }
}
