.setting {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    overflow-y: auto;

    .profile {
        position: relative;
        margin-top: 20px;
        width: 100%;
        overflow: hidden;

        img {
            border: 1px solid #666666;
            border-radius: 100%;
            width: 120px;
            height: 120px;
        }
    }

    .apikey {
        height: auto;
        width: calc(100% - 80px);
        text-align: left;
        padding: 40px;

        .edit-box {
            width: calc(100% - 40px);
            display: flex;

            .label {
                width: 10%;
                text-align: center;
                min-width: 100px;

                i {
                    font-weight: bold;
                    color: #222222;
                    cursor: pointer;

                    .key-tooltip {
                        visibility: hidden;
                        position: absolute;
                        background-color: #666666;
                        padding: 10px;
                        color: white;
                        border-radius: 0 12px 12px 12px;
                        margin-left: 80px;
                    }

                    &:hover .key-tooltip {
                        visibility: visible;
                    }
                }
            }

            .key-text {
                width: calc(90% - 20px);

                textarea {
                    border-radius: 8px;
                    padding: 10px;
                    width: 100%;
                    height: 50px;
                    resize: none;
                    border: 1px solid #222222;
                }
            }
        }

        .submit-box {
            width: calc(100% - 40px);
            text-align: right;

            button {
                font-size: 14px;
                color: white;
                background-color: #007bff;
                border: none;
                padding: 10px;
                border-radius: 12px;
                cursor: pointer;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
}
