.content {
    width: 100%;
}

@media (max-width: 500px) {
    .content {
        width: 100%;
        margin-top: 40px;
    }
}
