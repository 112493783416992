.agent-select-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    overflow: auto;
    align-items: center;
    z-index: 1000;
}

.agent-select-modal {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 700px;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: flex-end; // 버튼을 오른쪽으로 정렬
    margin-top: 20px;
}

.agent-select-modal h2 {
    margin-bottom: 20px;
    text-align: center;
}

.agent-detail-button {
    font-size: 12px;
    color: white;
    background-color: #007bff; // 빨간색 계열
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: #175fc7; // 호버 시 더 어두운 빨간색
        transform: scale(1.05); // 약간 확대하여 피드백 제공
    }

    &:active {
        background-color: #3498db; // 클릭 시 더 어두운 빨간색
        transform: scale(0.95); // 눌리는 듯한 효과
    }
}

.agent-select-button {
    font-size: 12px;
    color: white;
    background-color: #007bff;
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: #175fc7;
        transform: scale(1.05);
    }

    &:active {
        background-color: #3498db;
        transform: scale(0.95);
    }
}

.agent-delete-button {
    font-size: 12px;
    color: white;
    background-color: #dc3545;
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: #c82333;
        transform: scale(1.05);
    }

    &:active {
        background-color: #bd2130;
        transform: scale(0.95);
    }
}
