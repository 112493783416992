.FileList {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow-y: scroll;

    .addFile {
        width: 80%;
        margin-left: 10%;
        text-align: right;
        margin-top: 40px;
        font-size: 24px;

        i {
            border-radius: 100%;
            cursor: pointer;
        }

        i:hover {
            color: #175fc7;
        }
    }
}
