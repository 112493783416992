.userList {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow-y: scroll;

    .addUser {
        width: 80%;
        margin-left: 10%;
        text-align: right;
        font-size: 24px;

        i {
            cursor: pointer;
        }

        i:hover {
            color: #175fc7;
        }
    }
}

@media (max-width: 500px) {
    .userList {
        .userListTable {
            table {
                th:nth-child(1),
                td:nth-child(1) {
                    display: none;
                }

                th:nth-child(2),
                td:nth-child(2) {
                    width: 25%;
                }
            }
        }
    }
}
