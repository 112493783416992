.modal {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .modalBody {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        animation: fadeIn 1s;
        border-radius: 12px;
        background-color: white;
        padding: 20px;
        box-shadow: 8px 8px 25px hsl(0deg 0% 0% / 0.23);

        .closeBtn {
            position: absolute;
            top: -40px;
            font-size: 25px;
            right: 0;
            color: white;
        }

        .modalTitle {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: black;
            width: 100%;
        }

        div {
            .Modal_action_box {
                height: 12%;
                margin-top: auto;
                display: flex;
                justify-content: center;

                button {
                    opacity: 0.8;
                    height: 30px;
                    width: 80px;
                    border-radius: 12px;
                    font-size: 14px;
                    font-weight: bold;
                    background-color: #bbbbbb;
                    margin-left: 5px;
                    margin-right: 5px;
                    border: 0;
                    cursor: pointer;
                }

                button:first-child {
                    color: white;
                    background-color: #175fc7;
                }

                button:hover {
                    opacity: 1;
                }
            }
        }
    }
}
