.agent-select-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // 반투명 검정 배경
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // 다른 콘텐츠 위에 표시되도록
    overflow-y: auto; // 모달이 화면을 넘어가면 스크롤 가능하도록
}

.agent-select-modal {
    width: 90%; // 반응형으로 너비 조정
    max-width: 900px; // 최대 너비 제한
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 20px auto; // 화면 상하 여백 추가
    box-sizing: border-box; // 패딩 포함 크기 조정
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.form-group {
    margin-bottom: 20px; // 각 입력 필드 사이에 여백 추가
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-bottom: 5px; // 라벨과 입력 필드 사이 여백 추가
}

input[type="text"],
textarea {
    width: 100%; // 텍스트 박스가 모달 내부에 정확히 맞게 조정
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; // 패딩 포함 크기 조정
}

textarea {
    resize: vertical; // 텍스트 영역 크기 조정 가능
}

.radio-group,
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; // 버튼 간격 추가
    justify-content: center; // 버튼들을 가운데 정렬
}

.radio-group label,
.checkbox-group label {
    display: flex; // 라벨과 버튼을 한 줄로 정렬
    align-items: center;
    gap: 5px; // 버튼과 텍스트 사이 간격 추가
}

.submit-box {
    text-align: center; // 저장 버튼을 중앙 정렬
    margin-top: 20px; // 버튼 위쪽에 여백 추가
}

button[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}
