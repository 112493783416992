.AgentListTable {
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;

    // table layout
    table {
        text-align: center;
        white-space: normal;
        th:nth-child(1) {
            width: 5%;
        }

        th:nth-child(2) {
            width: 5%;
            text-align: center;
        }

        th:nth-child(3) {
            width: 45%;
        }

        td:nth-child(3) {
            padding: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
        }

        th:nth-child(4) {
            width: 15%;
        }

        th:nth-child(5) {
            width: 6%;
        }

        td:nth-child(5) {
            i {
                font-size: 24px;
            }
        }

        th:nth-child(6) {
            width: 6%;
        }

        th:nth-child(7) {
            width: 8%;
        }

        th:nth-child(7) {
            width: 20%;
        }

        // action
        th:nth-child(8),
        td:nth-child(8) {
            width: 5%;

            i {
                font-size: 24px;
                opacity: 0.8;
                cursor: pointer;
            }

            i:hover {
                color: #175fc7;
                opacity: 1;
            }

            .actionTooltip {
                width: auto;
                position: fixed;
                text-align: left;
                margin-left: 60px;
                background-color: white;
                box-shadow: 8px 8px 25px hsl(0deg 0% 0% / 0.23);

                .actionBtn {
                    color: black;
                    cursor: pointer;
                    padding: 10px;
                    opacity: 0.8;

                    i {
                        font-size: 15px;
                    }
                }

                .actionBtn:nth-child(2) {
                    border-top: 0;
                }

                .actionBtn:hover {
                    opacity: 1;
                    color: #175fc7;
                }
            }
        }
    }

    .table_page {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;

        button {
            margin-left: 5px;
            margin-right: 5px;
            border: 1px solid black;
            border-radius: 8px;
            font-weight: bold;
            margin-top: 20px;
            width: 30px;
            padding: 5px;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }
}

@media (max-width: 500px) {
    .AgentListTable {
        table {
            th,
            td {
                font-size: 12px;
            }

            th:nth-child(1),
            td:nth-child(1) {
                display: none;
            }

            th:nth-child(3),
            td:nth-child(3) {
                width: 20%;
            }

            th:nth-child(4),
            td:nth-child(4) {
                display: none;
            }

            th:nth-child(5),
            td:nth-child(5) {
                width: 8%;
            }

            th:nth-child(6),
            td:nth-child(6) {
                display: none;
            }

            th:nth-child(7),
            td:nth-child(7) {
                width: 8%;

                .actionTooltip {
                    width: auto;
                    text-align: left;
                    margin-left: -85px;

                    .actionBtn {
                        color: black;
                        cursor: pointer;
                        padding: 10px;
                        opacity: 0.8;

                        i {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
