@keyframes fadeOutLeft {
    from {
        margin-left: 0px;
        transform: translate3d(-0.5%, 0, 0);
    }
    to {
        margin-left: -170px;
        transform: translateZ(0);
    }
}

@keyframes fadeInRight {
    from {
        margin-left: -170px;
        transform: translate3d(-0.5%, 0, 0);
    }
    to {
        margin-left: 0px;
        transform: translateZ(0);
    }
}

.sideMenu {
    position: relative;
    z-index: 1;
    text-align: center;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    background-color: #222222;
    box-shadow: 8px 8px 25px hsl(0deg 0% 0% / 0.23);
    padding: 10px;

    .nav {
        width: 100%;
        height: 40px;
        text-align: left;
        line-height: 40px;
        font-size: 15px;
        cursor: pointer;
        color: white;

        i {
            margin-left: 20px;
        }

        label {
            margin-left: 15px;
            cursor: pointer;
        }

        &:hover {
            font-weight: bold;
        }
    }

    .nav_active {
        border-radius: 10px;
        width: 100%;
        height: 40px;
        text-align: left;
        line-height: 40px;
        font-size: 15px;
        color: white;
        cursor: pointer;
        font-weight: bold;
        background-color: rgba(226, 226, 226, 0.2);

        i {
            margin-left: 20px;
        }

        label {
            margin-left: 15px;
            cursor: pointer;
        }
    }

    .sideMenu_expand {
        position: absolute;
        left: 10px;
        width: 150px;
        text-align: center;
        bottom: 35px;
        font-size: 30px;
        color: white;

        i {
            cursor: pointer;
            font-size: 30px;
        }

        .open_side_menu {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 160px;
            border-radius: 0 25px 25px 0;
            background-color: #007bff;
            animation: fadeInRight 0.5s;
            box-shadow: 8px 8px 25px hsl(0deg 0% 0% / 0.23);
            cursor: pointer;
            padding: 0 0 5px 0;
            i {
                color: white;
                padding: 10px;
                border-radius: 100%;
            }
        }
    }
}

@media (max-width: 500px) {
    .sideMenu {
        position: absolute;
        display: flex;
        width: 100%;
        min-width: 100%;
        min-height: auto;
        height: 43px;
        right: 0;
        padding: 0px;

        i {
            display: none;
        }

        .nav {
            text-align: center;
            height: 10px;

            label {
                margin-left: 0px;
            }
        }

        .nav_active {
            text-align: center;
            border-radius: 0px;
            background-color: rgba(0, 0, 0, 0);
            border-bottom: 3px solid white;

            label {
                margin-left: 0px;
            }
        }

        .sideMenu_expand {
            display: none;
        }
    }
}
