@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.chat-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;

    .chat-list {
        position: relative;
        flex: 1;
    }

    .chat-content {
        position: relative;
        height: 100%;
        flex: 9;
    }
}

.model-select {
    text-align: center;
    display: flex;

    .model-btn {
        //margin-top: 20px;
        flex: 1;
        padding: 10px;

        div {
            cursor: pointer;
            border: 1px solid #666666;
            border-radius: 12px;
            line-height: 40px;
            height: 40px;
        }
    }
}

@media (max-width: 500px) {
    .chat-container {
        height: calc(100% - 40px);
        .chat-list {
            position: relative;
            width: 100px;
            font-size: 12px;
        }

        .chat-content {
            position: relative;
            flex: 9;
        }
    }
}
