.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // 그림자
    overflow: hidden;
    border-radius: 8px; // 테이블 전체에 둥근 모서리 적용

    th {
        background-color: rgba(0, 123, 255, 0.8); // 반투명한 헤더 배경색
        color: white;
        height: 35px; // 높이
        border: 1px solid #dddddd;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px; // 패딩
        font-weight: bold;
        font-size: 14px; // 글꼴 크기
        text-transform: uppercase;
    }

    tr {
        transition: background-color 0.3s;

        &:hover {
            background-color: #f1f1f1;
        }

        td {
            height: 30px; // 높이
            border: 1px solid #dddddd;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 5px; // 패딩
            font-size: 14px; // 글꼴 크기
            color: #333;
        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: #f9f9f9;
        }
    }
}
