.userCreateModal {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

    .user-input-box {
        width: 80%;
        height: 80%;
        margin-left: 10%;
        overflow-x: hidden;

        // atomic component 수정
        .inputBox {
            margin-top: 20px;
            width: 100%;

            input {
                font-size: 14px;
                line-height: 15px;
            }

            label {
                font-size: 16px;
            }

            input:focus ~ label,
            input:valid ~ label {
                font-size: 10px;
                bottom: 30px;
                color: #666;
                font-weight: bold;
            }
        }

        .notMatch_password {
            width: 100%;
            color: red;
            font-size: 12px;
            text-align: right;
        }

        .match_password {
            width: 100%;
            color: blue;
            font-size: 12px;
            text-align: right;
        }

        .user_id_name_input {
            margin-top: 25px;
            display: flex;
            gap: 50px;
        }

        .role-selection {
            margin-top: 10px;
            text-align: left;

            .role-title {
                font-weight: bold;
                margin-bottom: 5px;
            }

            .role-option {
                label {
                    font-size: 17px;
                    line-height: 2rem;
                    padding: 0.2em 0.4em;
                    vertical-align: 2px;
                }

                [type="radio"] {
                    appearance: none;
                    border: max(2px, 0.1em) solid gray;
                    border-radius: 50%;
                    width: 1.25em;
                    height: 1.25em;
                    transition: border 0.2s ease-in-out;
                }

                [type="radio"]:checked {
                    border: 0.4em solid #0056b3;
                }
            }
        }
    }
}
