.userListTable {
    width: 80%;
    text-align: center;
    margin-left: 10%;
    margin-top: 10px;

    table {
        th {
            color: white;
            background-color: #666666;
        }

        //order
        th:nth-child(1) {
            width: 7%;
        }

        //id
        th:nth-child(2) {
            width: 35%;
        }

        //name
        th:nth-child(3) {
            width: 35%;
        }

        //role
        th:nth-child(4) {
            width: 15%;
        }

        //action
        th:nth-child(5) {
            width: 8%;
        }
    }

    .table_page {
        width: 100%;
        margin-bottom: 20px;

        button {
            margin-left: 5px;
            margin-right: 5px;
            border: 1px solid black;
            border-radius: 8px;
            font-weight: bold;
            margin-top: 20px;
            width: 30px;
            padding: 5px;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }
}
