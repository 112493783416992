.login {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 9999;
    height: calc(100% + 100px);
    text-align: center;

    .login_div {
        flex: 1;
        padding-top: 40px;
        font-size: 36px;
        text-align: center;
        min-width: 250px;
        box-shadow: 8px 8px 25px hsl(0deg 0% 0% / 0.4);

        p {
            color: gray;
            text-align: right;
            margin-right: 20px;
            font-size: 18px;
        }

        img {
            width: 80%;
            max-width: 250px;
            margin-top: 50px;
        }

        .LoginBox {
            margin-top: 20%;
            margin-left: 10%;
            width: 80%;

            .inputBox {
                margin-top: 15px;
                width: 100%;
            }

            .forgetPassword {
                text-align: right;
                margin-top: 10px;
                font-size: 14px;
                color: #175fc7;
                opacity: 0.8;
                cursor: pointer;
            }

            .forgetPassword:hover {
                opacity: 1;
                text-decoration: underline;
            }
        }

        .loginSubmit {
            width: 40%;
            height: 40px;
            margin-top: 10px;
            margin-left: 30%;
            text-align: center;
            font-size: 14px;
            line-height: 40px;
            cursor: pointer;
            background-color: #175fc7;
            color: white;
            border-radius: 15px;
            opacity: 0.8;
        }

        .loginSubmit:hover {
            opacity: 1;
        }

        .loginFailMessage {
            width: 100%;
            padding: 10px 0px 10px 10px;
            text-align: left;
            margin-top: 10px;
            font-size: 14px;
            border-radius: 5px;
            background-color: #f1cdd1;
            color: red;
        }
    }

    .login_Info {
        flex: 3;
        width: 100%;
        background-image: url("../../../../public/images/login_background.jpg");
        background-size: cover;

        .intro_text {
            margin-top: 20%;
            font-size: 45px;
            color: white;
            font-weight: bold;
            animation: fadeInUp 3s;
        }
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

@media (max-width: 500px) {
    .login {
        .login_Info {
            display: none;
        }
    }
}
