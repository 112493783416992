@keyframes fadeInUp {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    to {
        transform: translateZ(0);
    }
}

.chat-settings {
    z-index: 9;
    background-color: white;
    text-align: left;
    //padding: 10px 0 10px 0;
    padding: 20px;
    position: absolute;
    animation: fadeInUp 1s;
    min-width: 350px;
    bottom: 0;
    right: 0;
    border-radius: 20px 20px 0 0;
    box-shadow: 8px 8px 25px hsl(0deg 0% 0% / 0.23);

    .title {
        font-size: 20px;
        width: 100%;
        text-align: left;
        i:nth-child(1) {
            margin-left: 15px;
            text-align: left;
            opacity: 1;
        }

        i:nth-child(2) {
            position: absolute;
            margin-top: 0;
            right: 30px;
            cursor: pointer;
        }

        margin-bottom: 20px;
    }

    .sub_title {
        font-size: 15px;
        font-weight: bold;

        i {
            margin-right: 10px;
        }
    }

    .llm-select {
        div {
            margin-left: 20px;
            line-height: 40px;
        }

        padding-right: 10px;
        padding-left: 10px;
        display: flex;
        margin-bottom: 10px;

        div:nth-child(1) {
            text-align: left;
            flex: 3;
        }
    }
}
